import { graphql, useStaticQuery } from 'gatsby';

function useProjectsResidentialPage() {
  const data = useStaticQuery(
    graphql`
      query ProjectsResidentialPageQuery {
        wpgraphql {
          pageBy(uri: "projects-residential") {
            acf_hero {
              heroTitle
              heroDescription
              heroImage {
                sourceUrl
              }
            }
            acf_projects_content {
              projectsSecondTitle
              projectsContentDescription
              projectsFirstTitle
            }
            acf_projects_slider {
              projectList {
                projectLocation
                projectName
                projectGallery {
                  sourceUrl
                }
              }
            }
          }

          services(where: { orderby: { field: SLUG, order: ASC } }) {
            nodes {
              title
              excerpt
              acf_service_info {
                serviceUrl
                icon {
                  sourceUrl
                }
                background {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useProjectsResidentialPage };
