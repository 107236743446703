import { graphql, useStaticQuery } from 'gatsby';

function useMaintenanceService() {
  const data = useStaticQuery(
    graphql`
      query MaintenanceServiceQuery {
        wpgraphql {
          serviceBy(serviceId: 151) {
            acf_service_info {
              serviceTitle
              serviceShortDescription
              serviceUrl
              icon {
                sourceUrl
              }
              background {
                sourceUrl
              }
            }
            acf_text_block_title {
              title
              content
              inlineRow
            }
            acf_image_text_block {
              blockList {
                blockStyle
                title
                content
                gallery {
                  sourceUrl
                }
                image {
                  sourceUrl
                }
                imagesFlipCard {
                  imagesFlip {
                    sourceUrl
                  }
                }
              }
            }
            acf_quote {
              titleQuoteSection
              descriptionQuote
              phoneNumber
              backgroundImage {
                sourceUrl
              }
              iconQuote {
                sourceUrl
              }
            }
            acf_services_block {
              servicesList {
                serviceTitle
                serviceDescription
                serviceGallery {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useMaintenanceService };
