import { graphql, useStaticQuery } from 'gatsby';

function useAboutResidentialPage() {
  const data = useStaticQuery(
    graphql`
      query AboutResidentialPageQuery {
        wpgraphql {
          pageBy(uri: "about-residential") {
            acf_hero {
              heroTitle
              heroDescription
              heroImage {
                sourceUrl
              }
            }
            acf_text_block_title {
              title
              content
            }
            acf_about_content {
              aboutFirstTitle
              aboutSecondTitle
            }
            acf_image_about_container {
              frontImage {
                sourceUrl
              }
              backImage {
                sourceUrl
              }
            }
            acf_paragraph_title {
              primaryTitle
              primaryContent
              secondTitle
              secondContent
              hasTwoBlocks
            }
          }

          services(where: { orderby: { field: SLUG, order: ASC } }) {
            nodes {
              title
              excerpt
              acf_service_info {
                serviceUrl
                icon {
                  sourceUrl
                }
                background {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useAboutResidentialPage };
