import { graphql, useStaticQuery } from 'gatsby';

function useHomeResidentialPage() {
  const data = useStaticQuery(
    graphql`
      query HomeResidentialPageQuery {
        wpgraphql {
          pageBy(uri: "home-residential") {
            acf_hero {
              fieldGroupName
              sliderTitle
              sliderImages {
                image {
                  altText
                  sourceUrl
                }
              }
            }

            acf_text_block_title {
              title
              content
              hastTwoBlocks
              secondTitle
              secondContent
            }
            acf_image_text_block {
              blockList {
                title
                content
                image {
                  sourceUrl
                }
                imageTextBlockButton {
                  url
                }
              }
            }

            acf_testimonials {
              mainTitle
              mainContent
              image {
                altText
                sourceUrl
              }
              testimonials {
                testimonialAuthor
                testimonialText
              }
            }

            acf_contact_cta {
              contactCtaTitle
              contactCtaContent
              contactCtaButton {
                url
                title
              }
              contactCtaBackgroundImage {
                altText
                sourceUrl
              }
            }
          }

          residentialServices(where: { orderby: { field: SLUG, order: ASC } }) {
            nodes {
              title
              excerpt
              acf_service_info {
                serviceUrl
                icon {
                  sourceUrl
                }
                background {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useHomeResidentialPage };
