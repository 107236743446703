import { graphql, useStaticQuery } from 'gatsby';

function useRemodelingService() {
  const data = useStaticQuery(
    graphql`
      query RemodelingQuery {
        wpgraphql {
          residentialServiceBy(residentialServiceId: 459) {
            acf_text_block_title {
              title
              content
            }
            acf_image_text_block {
              blockList {
                title
                content
                image {
                  sourceUrl
                }
              }
            }
            acf_service_info {
              serviceTitle
              serviceShortDescription
              icon {
                sourceUrl
              }
              background {
                sourceUrl
              }
              serviceUrl
            }
            acf_services_block {
              servicesList {
                serviceGallery {
                  sourceUrl
                }
                serviceTitle
                serviceDescription
              }
            }
            acf_quote {
              backgroundImage {
                sourceUrl
              }
              titleQuoteSection
              descriptionQuote
              phoneNumber
              iconQuote {
                sourceUrl
              }
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useRemodelingService };
