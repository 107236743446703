import { graphql, useStaticQuery } from 'gatsby';

function useContactCommercialPage() {
  const data = useStaticQuery(
    graphql`
      query ContactCommercialPageQuery {
        wpgraphql {
          pageBy(uri: "contact-us-commercial") {
            acf_hero {
              heroTitle
              heroDescription
              heroImage {
                sourceUrl
              }
            }
            acf_map_location {
              locationUrl
            }
            acf_contact_information {
              address
              email
              phoneNumber
            }
          }
        }
      }
    `,
  );
  return data.wpgraphql;
}

export { useContactCommercialPage };
